.form-custom-mat-fields {
  min-width: 150px;
  max-width: 500px;
  width: 100%;
}

.custom-mat-fields-full-width {
  width: 100%;
}

.mat-dialog-title {
  padding: 8px 22px 0px 26px;
}

.custom-mat-menu {
  font-size: 14px !important;
  font-weight: bold !important;
  padding-bottom: 5px;
}

.mdc-label {
  color: #333635
}

.mat-mdc-checkbox {
  --mdc-checkbox-selected-pressed-icon-color: rgb(76, 186, 255) !important;
  --mdc-checkbox-selected-checkmark-color: white !important;
  --mdc-checkbox-selected-focus-icon-color: rgb(76, 186, 255) !important;
  --mdc-checkbox-selected-hover-icon-color: rgb(76, 186, 255) !important;
  --mdc-checkbox-selected-icon-color: rgb(76, 186, 255) !important;
  --mdc-checkbox-selected-focus-state-layer-color: rgb(76, 186, 255) !important;
  --mdc-checkbox-selected-hover-state-layer-color: rgb(76, 186, 255) !important;
  --mdc-checkbox-unselected-pressed-state-layer-color: rgb(76, 186, 255) !important;
  --mat-checkbox-disabled-label-color: rgba(125, 125, 125, 0.38) !important;
  --mat-checkbox-label-text-color: #e0e3e2;
}

kendo-grid-column-menu {
  .k-grid-header-menu.k-grid-column-menu.k-active {
    background-color: #03a9f4 !important;
  }
}