// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

// https://themes.angular-material.dev/ for theming if necessary
// else use static styles in the botom half of the file

// https://angular-material.dev/articles/angular-material-theme-builder
@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$project-primary: mat.m2-define-palette(mat.$m2-light-blue-palette);
$project-accent:  mat.m2-define-palette(mat.$m2-deep-orange-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$project-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$project-theme: mat.m2-define-light-theme((color: (primary: $project-primary,
        accent: $project-accent,
        warn: $project-warn,
      ),
      typography: mat.m2-define-typography-config(),
      density: 0));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// 
@include mat.all-component-themes($project-theme);

@import '~normalize.css/normalize.css';
@import 'typography';
// @import 'theme/theme';
@import 'hovers/hovers';
@import 'cards';
@import "customKendoMaterialTheme/customKendoMaterialTheme.css";
@import "material-custom.scss";

/************************************** COMMON STYLES **************************************/
html,
body,
mat-sidenav-container {
  margin: 0;
  width: 100%;
  height: 100%;
}

//make pages display block for route animation
router-outlet~* {
  display: block;
  // width: 100%;
  // height: 100%;
}

/************************************** SIDE PANEL STYLES **************************************/
.mat-sidenav {
  width: 300px;
  @include mat.elevation(16);
}

/************************************** TOOLBAR STYLES **************************************/
.mat-toolbar {
  @include mat.elevation(4);
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
