html {
  body {
    .mat-mdc-card {
      padding: 24px;
      .mat-mdc-card-actions {
        margin-left: -16px;
        margin-right: -16px;
        padding: 8px 0;
      }
      .mat-mdc-card-footer {
        margin: 0 -24px -18px -24px
      }
      &, & .mat-mdc-card {
        & .mat-mdc-card-actions:last-child {
          margin-bottom: -16px;
          margin-left: -16px;
          margin-right: -16px;
          padding: 8px 0;
        }
      }
    }
  }
}
